function Pagemeter(selector, initEvent, destroyEvent) {
  const obj = {};

  obj.selector = selector;
  obj.initEvent = initEvent;
  obj.destroyEvent = destroyEvent;

  obj.init = function() {
    obj.form = $(obj.selector)
    obj.locale = $('html').prop('lang')

    if (obj.form.length > 0) {
      text = $('[name="text"]', obj.form);
      text.on('change keyup', obj.calculatePages);
      text.on('focus', function() { this.setSelectionRange(0, this.value.length) });
      text.change();


      $('input, select', obj.form).on('change', obj.calculatePages);
    }
  }

  obj.destroy = function() {
    if (obj.form.length > 0) {
      $('[name="text"]', obj.form).off('change keyup');
      $('input, select', obj.form).off('change')
    }
  }

  obj.calculateWordsCount = function(text) {
    words = text.replace(/[^A-Za-z0-9А-Яа-яЁё]/g, ' ').split(' ').filter(function (el) { return el != ""});

    return words.length;
  }

  obj.calculatePagesByChrono = function() {
    hours   = Number($('[name="hours"]', obj.form).val());
    minutes = Number($('[name="minutes"]', obj.form).val());
    seconds = Number($('[name="seconds"]', obj.form).val());

    totalSeconds = seconds + (minutes * 60) + (hours * 3600);

    wordsPerSecond = Number(obj.form.data().wordsPerSecond);

    return obj.wordsCountToPages(totalSeconds * wordsPerSecond);
  }

  obj.calculatePagesByText = function() {
    wordsCount = obj.calculateWordsCount($('[name="text"]', obj.form).val());

    return obj.wordsCountToPages(wordsCount);
  }

  obj.wordsCountToPages = function(wordsCount) {
    return Math.ceil(10 * wordsCount / obj.wordsPerPage()) / 10;
  }

  obj.wordsPerPage = function() {
    fontData = $('[name="font"] option:selected', obj.form).data('words-per-page');
    fontSize = $('[name="font_size"]', obj.form).val();

    return fontData[fontSize];
  }

  obj.calculatePages = function(event) {
    if ($('.tab-pane.active [name="text"]', obj.form).length > 0) {
      pagesCount = obj.calculatePagesByText()
    } else {
      pagesCount = obj.calculatePagesByChrono()
    }

    $('[name="pages_count"]', obj.form).val(pagesCount);

    return true;
  }

  document.addEventListener(obj.initEvent, obj.init);
  document.addEventListener(obj.destroyEvent, obj.destroy);

  return obj;
}

Pagemeter('#pagemeter', 'turbolinks:load', 'turbolinks:before-cache');
