function Chronomer(selector, initEvent, destroyEvent) {
  const obj = {};

  obj.selector = selector;
  obj.initEvent = initEvent;
  obj.destroyEvent = destroyEvent;

  obj.init = function() {
    obj.form = $(obj.selector)
    obj.locale = $('html').prop('lang')

    if (obj.form.length > 0) {
      text = $('[name="text"]', obj.form);
      text.on('change keyup', obj.onTextChange);
      text.on('focus', function() { this.setSelectionRange(0, this.value.length) });
      text.change();
    }
  }

  obj.destroy = function() {
    if (obj.form.length > 0) {
      $('[name="text"]', obj.form).off('change keyup');
    }
  }

  obj.calculateWordsCount = function(text) {
    words = text.replace(/[^A-Za-z0-9А-Яа-яЁё]/g, ' ').split(' ').filter(function (el) { return el != ""});

    return words.length;
  }

  obj.calculateTime = function(count, wordsPerSecond) {
    return Math.round(count / wordsPerSecond);
  }

  obj.onTextChange = function(event) {
    wordsCount = obj.calculateWordsCount($(this).val());

    fastPaceSeconds = obj.calculateTime(wordsCount, obj.form.data().wordsPerSecond['fast'])
    optimalPaceSeconds = obj.calculateTime(wordsCount, obj.form.data().wordsPerSecond['optimal'])

    $('[name="words_count"]', obj.form).val(wordsCount);
    $('[name="fast_pace"]', obj.form).val(obj.secondsToString(fastPaceSeconds));
    $('[name="optimal_pace"]', obj.form).val(obj.secondsToString(optimalPaceSeconds));
  }

  obj.secondsToString = function(totalSeconds) {
    hours   = Math.floor(totalSeconds / 3600);
    minutes = Math.floor((totalSeconds - (hours * 60)) / 60);
    seconds = totalSeconds - (hours * 3600) - (minutes * 60);

    text = [
      String(hours).padStart(2, 0),
      String(minutes).padStart(2, 0),
      String(seconds).padStart(2, 0)
    ].join(':');

    return text;
  }

  document.addEventListener(obj.initEvent, obj.init);
  document.addEventListener(obj.destroyEvent, obj.destroy);

  return obj;
}

Chronomer('#chronomer', 'turbolinks:load', 'turbolinks:before-cache');
