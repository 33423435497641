$(document).on('turbolinks:load', function() {
  $('#records_filter input').on('change', function() { this.closest('form').submit() });

  $('audio').on('play', function(e) {
    var audios = $('audio');

    for(var i = 0, len = audios.length; i < len; i++) {
      if(audios[i] != e.target){
        audios[i].pause();
      }
    }
  })
});
